<template>
  <div>
    <div class="store-component-header">
      <div v-if="skeleton" class="store-header">
        <b-skeleton width="13%" height="33px" class="mx-auto mb-3"></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton width="90%" class="mx-auto"></b-skeleton>
        <b-skeleton width="30%" class="mx-auto"></b-skeleton>
      </div>
      <div v-else class="store-header">
        <h1 class="store-header-title">
          <span>{{ title }}</span>
        </h1>
        <p class="store-header-text">{{ description }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { BSkeleton } from 'bootstrap-vue'
export default {
  components: { BSkeleton },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    skeleton: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
