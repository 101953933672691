<template>
  <div class="d-block d-md-none my-4">
    <carousel
      class="col-12 px-0 mx-0 carousel-menu-profile"
      :dots="false"
      :lazy-load="true"
      :nav="false"
      :responsive="{ 0: { items: 2, nav: false, stagePadding: 30 } }"
    >
      <router-link
        v-for="(slot, index) in dataRoute"
        v-slot="{ href, navigate, isExactActive }"
        :key="index"
        class="item-menu-profile"
        :to="{ name: slot.route }"
      >
        <a :href="href" @click="navigate">
          <span :class="[isExactActive && 'text-item-menu-profile']">{{
            slot.text
          }}</span>
        </a>
      </router-link>
    </carousel>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
export default {
  components: { carousel },
  data() {
    return {
      dataRoute: [
        { text: 'Mis datos', route: 'Profile' },
        { text: 'Direcciones', route: 'ProfileAddress' },
        { text: 'Cambiar clave', route: 'CambiarClave' },
        { text: 'Pedidos', route: 'ProfileOrders' },
        { text: 'Favoritos', route: 'PerfilFavorites' },
      ],
    }
  },
  watch: {
    $route() {
      this.updateRoutes()
    },
  },
  mounted() {
    this.updateRoutes()
  },
  methods: {
    updateRoutes() {
      if (this.$route.name == 'Profile') {
        this.dataRoute = [
          { text: 'Mis datos', route: 'Profile' },
          { text: 'Direcciones', route: 'ProfileAddress' },
          { text: 'Cambiar clave', route: 'CambiarClave' },
          { text: 'Pedidos', route: 'ProfileOrders' },
          { text: 'Favoritos', route: 'PerfilFavorites' },
        ]
      } else if (this.$route.name == 'ProfileAddress') {
        this.dataRoute = [
          { text: 'Direcciones', route: 'ProfileAddress' },
          { text: 'Mis datos', route: 'Profile' },
          { text: 'Cambiar clave', route: 'CambiarClave' },
          { text: 'Pedidos', route: 'ProfileOrders' },
          { text: 'Favoritos', route: 'PerfilFavorites' },
        ]
      } else if (this.$route.name == 'CambiarClave') {
        this.dataRoute = [
          { text: 'Cambiar clave', route: 'CambiarClave' },
          { text: 'Mis datos', route: 'Profile' },
          { text: 'Direcciones', route: 'ProfileAddress' },
          { text: 'Pedidos', route: 'ProfileOrders' },
          { text: 'Favoritos', route: 'PerfilFavorites' },
        ]
      } else if (this.$route.name == 'ProfileOrders') {
        this.dataRoute = [
          { text: 'Pedidos', route: 'ProfileOrders' },
          { text: 'Mis datos', route: 'Profile' },
          { text: 'Direcciones', route: 'ProfileAddress' },
          { text: 'Cambiar clave', route: 'CambiarClave' },
          { text: 'Favoritos', route: 'PerfilFavorites' },
        ]
      } else if (this.$route.name == 'PerfilFavorites') {
        this.dataRoute = [
          { text: 'Favoritos', route: 'PerfilFavorites' },
          { text: 'Mis datos', route: 'Profile' },
          { text: 'Direcciones', route: 'ProfileAddress' },
          { text: 'Cambiar clave', route: 'CambiarClave' },
          { text: 'Pedidos', route: 'ProfileOrders' },
        ]
      } else if (this.$route.name == 'PerfilCredit') {
        this.dataRoute = [
          { text: 'Mi credito', route: 'PerfilCredit' },
          { text: 'Mis datos', route: 'Profile' },
          { text: 'Direcciones', route: 'ProfileAddress' },
          { text: 'Cambiar clave', route: 'CambiarClave' },
          { text: 'Pedidos', route: 'ProfileOrders' },
        ]
      }
    },
  },
}
</script>
