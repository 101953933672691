<template>
  <div>
    <base-profile />
    <div class="container-profile pb-5">
      <profile-mis-datos />
    </div>
  </div>
</template>
<script>
import BaseProfile from '@/components/Profile/BaseProfile'
import ProfileMisDatos from '@/components/Profile/ProfileMisDatos'
export default {
  components: { BaseProfile, ProfileMisDatos },
  mounted() {
    document.title = 'Perfil | DEREK Tienda Online'
    // this.$gtag.pageview(this.$route.fullPath)
  },
}
</script>
