<template>
  <div class="container-profile-page">
    <div v-if="token" class="container-profile-mis-datos">
      <div class="col-12 col-md-8 container-data-profile mt-5">
        <div v-if="dataUser">
          <div class="header-profile-mis-datos">
            <p>
              {{
                stepDataUser == 0
                  ? 'Datos personales'
                  : stepDataUser == 2
                  ? 'Cambiar contraseña'
                  : 'Editar datos personales'
              }}
            </p>
            <button
              class="secondary-button"
              type=""
              @click="
                stepDataUser == 0 ? (stepDataUser = 1) : (stepDataUser = 0)
              "
            >
              {{ stepDataUser == 0 ? 'Editar mis datos' : 'Cancelar' }}
            </button>
          </div>
          <div v-if="stepDataUser == 0">
            <p class="data-prodile-mis-datos">
              <strong>Correo electrónico</strong>:
              {{ dataUser.persona.correo ? dataUser.persona.correo : '-' }}
              <br />
              <strong>Nombre</strong>:
              {{ dataUser.persona.nombres ? dataUser.persona.nombres : '-' }}
              <br />
              <strong>Apellidos</strong>:
              {{
                dataUser.persona.apellidos ? dataUser.persona.apellidos : '-'
              }}
              <br />
              <strong>Cédula de ciudadanía</strong>:
              {{
                dataUser.persona.documento ? dataUser.persona.documento : '-'
              }}
              <br />
              <strong>Celular de contacto</strong>:
              {{ dataUser.persona.celular ? dataUser.persona.celular : '-' }}
              <br />
              <strong>Fecha de cumpleaños</strong>:
              {{ dataUser.persona.fechanac_dia }} de {{ getMonth.text }}<br />
              <strong>Genero</strong>:
              {{
                dataUser.persona.genero == 'M'
                  ? 'Hombre'
                  : dataUser.persona.genero == 'F'
                  ? 'Mujer'
                  : '-'
              }}
              <br />
            </p>
          </div>
          <div v-else-if="stepDataUser == 1" class="d-flex flex-wrap">
            <div class="col-12 position-relative">
              <input
                v-model="dataUser.persona.correo"
                class="payment-input input-login mb-4"
                placeholder="Correo electrónico"
                type="email"
                disabled
              />
              <span class="newlatter-error-input">Correo electrónico</span>
            </div>
            <div class="col-12 col-md-6 pe-md-3 position-relative">
              <input
                v-model="nombreUser"
                class="payment-input input-login mb-4"
                placeholder="Nombre"
                type="given-name"
                autocomplete="given-name"
                :style="nombreUser && validateNombre ? 'border-color: red' : ''"
                @focus="validateNombre = false"
                @blur="validateBlurUser('name')"
              />
              <span v-if="validateNombre" class="error-inputs pe-3"
                >nombre requerido</span
              >
              <span v-if="nombreUser" class="newlatter-error-input"
                >Nombre</span
              >
            </div>
            <div class="col-12 col-md-6 ps-md-3 position-relative">
              <input
                v-model="apellidosUser"
                class="payment-input input-login mb-4"
                placeholder="Apellidos"
                type="family-name"
                :style="
                  apellidosUser && validateApellido ? 'border-color: red' : ''
                "
                @focus="validateApellido = false"
                @blur="validateBlurUser('lastName')"
              />
              <span v-if="validateApellido" class="error-inputs ps-3"
                >apellido requerido</span
              >
              <span
                v-if="apellidosUser"
                class="newlatter-error-input"
                style="left: 1.5rem"
                >Apellidos</span
              >
            </div>
            <div class="col-12 col-md-6 pe-md-3 position-relative">
              <select
                v-model="tipoDoc"
                class="payment-input payment-input-right ms-0 w-100"
                style="min-height: 50px"
                :style="tipoDoc && validateTipoDoc ? 'border-color: red' : ''"
                @focus="validateTipoDoc = false"
                @blur="validateBlurUser('tipoDoc')"
              >
                <option :value="null">Tipo de documento</option>
                <option value="CC">Cédula de ciudadanía</option>
                <option value="PAS">Pasaporte</option>
                <option value="NIT">Nit</option>
                <option value="CE">Cédula de extranjería</option>
              </select>
              <span v-if="validateTipoDoc" class="error-inputs pe-3"
                >Tipo de documento requerido</span
              >
              <span v-if="tipoDoc" class="newlatter-error-input"
                >Tipo de documento</span
              >
            </div>
            <div class="col-12 col-md-6 ps-md-3 position-relative">
              <input
                v-model="doc"
                class="payment-input input-login mb-4"
                placeholder="Documento"
                type="number"
                :style="doc && validateDoc ? 'border-color: red' : ''"
                @focus="validateDoc = false"
                @blur="validateBlurUser('doc')"
              />
              <span v-if="validateDoc" class="error-inputs ps-3"
                >documento invalido</span
              >
              <span
                v-if="doc"
                class="newlatter-error-input"
                style="left: 1.5rem"
                >Documento</span
              >
            </div>
            <div class="col-12 col-md-6 pe-md-3 position-relative">
              <input
                v-model="tel"
                class="payment-input input-login mb-4"
                placeholder="Celular de contacto"
                type="tel"
                name="celular"
                :style="tel && validateTel ? 'border-color: red' : ''"
                @focus="validateTel = false"
                @blur="validateBlurUser('tel')"
              />
              <span v-if="validateTel" class="error-inputs pe-3"
                >Celular invalido</span
              >
              <span v-if="tel" class="newlatter-error-input"
                >Celular de contacto</span
              >
            </div>
            <div class="col-12 col-md-6 ps-md-3 position-relative">
              <select
                v-model="genero"
                class="payment-input payment-input-right ms-0 w-100"
                style="min-height: 50px"
                :style="genero && validateGenero ? 'border-color: red' : ''"
                @focus="validateGenero = false"
                @blur="validateBlurUser('gender')"
              >
                <option :value="null">Genero</option>
                <option value="M">Hombre</option>
                <option value="F">Mujer</option>
                <option value="O">Otro</option>
              </select>
              <span v-if="validateGenero" class="error-inputs ps-3"
                >Genero requerido</span
              >
              <span
                v-if="genero"
                class="newlatter-error-input"
                style="left: 1.5rem"
                >Genero</span
              >
            </div>
            <div class="col-12 col-md-6 pe-md-3 position-relative">
              <select
                v-model="dia"
                class="payment-input payment-input-right ms-0 w-100"
                style="min-height: 50px"
                :style="dia && validateDia ? 'border-color: red' : ''"
                @focus="validateDia = false"
                @blur="validateBlurUser('day')"
              >
                <option :value="null">Día de cumpleaños</option>
                <option
                  v-for="(day, index) in 31"
                  :key="index"
                  :value="day < 10 ? '0' + day : day"
                  >{{ day }}</option
                >
              </select>
              <span v-if="validateDia" class="error-inputs pe-3"
                >Día requerido</span
              >
              <span v-if="dia" class="newlatter-error-input"
                >Día de cumpleaños</span
              >
            </div>
            <div class="col-12 col-md-6 ps-md-3 position-relative">
              <select
                v-model="mes"
                class="payment-input payment-input-right ms-0 w-100"
                style="min-height: 50px"
                :style="mes && validateMes ? 'border-color: red' : ''"
                @focus="validateMes = false"
                @blur="validateBlurUser('month')"
              >
                <option :value="null">Mes de cumpleaños</option>
                <option
                  v-for="(month, index2) in months"
                  :key="index2"
                  :value="month.value"
                  >{{ month.text }}</option
                >
              </select>
              <span v-if="validateMes" class="error-inputs ps-3"
                >Mes requerido</span
              >
              <span
                v-if="mes"
                class="newlatter-error-input"
                style="left: 1.5rem"
                >Mes de cumpleaños</span
              >
            </div>
            <div class="col-12 text-center">
              <button
                class="me-md-3 edit-data-perfil-button"
                @click="stepDataUser = 0"
              >
                Cancelar
              </button>
              <button
                class="ms-md-3 edit-data-perfil-button"
                :class="loading ? 'px-3' : ''"
                @click="validateAndSendDataUser()"
              >
                <p v-if="!loading" class="mb-0 fs-6">Guardar</p>
                <div v-else class="d-flex px-0 mx-0">
                  Cargando...
                  <div class="loader" />
                </div>
              </button>
            </div>
            <div v-if="errorUpdatePerfil" class="msg-error-perfil">
              <p>{{ msgErrorUpdatePerfil }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 container-news-latter">
        <div class="new-latter-profile-datos">
          <div class="header-news-latter-profile-datos d-none d-md-flex">
            <h5><strong>Exclusiva para ti</strong></h5>
            <p>(Cumpleaños)</p>
          </div>
          <div class="img-news-latter-profile-datos mt-5 mt-md-0">
            <img src="/assets/Banner_promo_movil.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="profile-mis-direcciones py-5">
      <div
        class="container-bag-empty"
        style="height: 50vh; background-color: white"
      >
        <div class="text-center">
          <h3>Aún no has iniciado sesión</h3>
          <p class="fs-6">
            Para poder acceder a este modulo primero debes iniciar sesión.
          </p>
          <div class="mt-4" @click="$bvModal.show('modal-login')">
            <button class="btn-primary px-4 fs-6">Iniciar sesión</button>
          </div>
        </div>
      </div>
    </div>
    <footer-Returns />
  </div>
</template>
<script>
import FooterReturns from '@/components/Returns/FooterReturns'

import { mapState, mapActions } from 'vuex'
export default {
  components: { FooterReturns },
  data() {
    return {
      token: null,
      /******************************************/
      loading: false,
      updatePasswordalert: false,
      passwordNow: '',
      newPassword: '',
      stepDataUser: 0,
      months: [
        { value: '01', text: 'Enero' },
        { value: '02', text: 'Febrero' },
        { value: '03', text: 'Marzo' },
        { value: '04', text: 'Abril' },
        { value: '05', text: 'Mayo' },
        { value: '06', text: 'Junio' },
        { value: '07', text: 'Julio' },
        { value: '08', text: 'Agosto' },
        { value: '09', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
      ///////////////////////
      nombreUser: '',
      validateNombre: false,
      apellidosUser: '',
      validateApellido: false,
      tipoDoc: null,
      validateTipoDoc: false,
      doc: '',
      validateDoc: false,
      tel: '',
      validateTel: false,
      genero: null,
      validateGenero: false,
      dia: null,
      validateDia: false,
      mes: null,
      validateMes: false,
    }
  },
  computed: {
    ...mapState('user', [
      'dataUser',
      'msgErrorUpdatePerfil',
      'errorUpdatePerfil',
      'msgErrorUpdatePassPerfil',
      'errorUpdatePassPerfil',
    ]),
    getMonth() {
      if (this.dataUser.persona.fechanac_mes) {
        return this.months.find(
          e => e.value == this.dataUser.persona.fechanac_mes,
        )
      } else {
        return ''
      }
    },
    validatePhone: function() {
      const re = /^3[0-9]{9}/
      return (
        re.test(this.dataUser.persona.celular) &&
        this.dataUser.persona.celular.length < 11
      )
    },
    validateName: function() {
      return this.nombreUser.length > 2
    },
    validateLastName: function() {
      return this.apellidosUser.length > 2
    },
    validateDocument() {
      if (this.tipoDoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.doc) && this.doc.length >= 7 && this.doc.length <= 10
        )
      } else if (this.tipoDoc == 'CE') {
        const re2 = /([0-9]){6}/
        return re2.test(this.doc) && this.doc.length == 6
      } else if (this.tipoDoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){6}/
        return re3.test(this.doc) && this.doc.length >= 5
      } else if (this.tipoDoc == 'NIT') {
        return this.doc.length == 9
      }
      return false
    },
    validatePhoneUser() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  watch: {
    stepDataUser() {
      if (this.stepDataUser == 1) {
        this.nombreUser = this.dataUser.persona.nombres
        this.validateNombre = false
        this.apellidosUser = this.dataUser.persona.apellidos
        this.validateApellido = false
        this.tipoDoc = this.dataUser.persona.tipodoc
          ? this.dataUser.persona.tipodoc
          : null
        this.validateTipoDoc = false
        this.doc = this.dataUser.persona.documento
        this.validateDoc = false
        this.tel = this.dataUser.persona.celular
        this.validateTel = false
        this.genero = this.dataUser.persona.genero
          ? this.dataUser.persona.genero
          : null
        this.validateGenero = false
        this.dia = this.dataUser.persona.fechanac_dia
          ? this.dataUser.persona.fechanac_dia
          : null
        this.validateDia = false
        this.mes = this.dataUser.persona.fechanac_mes
          ? this.dataUser.persona.fechanac_mes
          : null
        this.validateMes = false
      }
    },
  },
  created() {
    this.token = window.localStorage.getItem('accessToken')
    if (this.$route.params.isRegister) {
      this.stepDataUser = 1
    }
  },
  methods: {
    ...mapActions('user', ['updatePerfil', 'updatePassword']),
    async validateAndSendDataUser() {
      if (
        this.validateName &&
        this.validateLastName &&
        this.tipoDoc &&
        this.validateDocument &&
        this.validatePhoneUser &&
        this.genero &&
        this.dia &&
        this.mes
      ) {
        this.updateInfo()
        this.loading = true
        await this.updatePerfil().then(() => {
          if (!this.errorUpdatePerfil) {
            this.stepDataUser = 0
            this.loading = false
          }
        })
      } else {
        this.validateBlurUser('name')
        this.validateBlurUser('lastName')
        this.validateBlurUser('tipoDoc')
        this.validateBlurUser('doc')
        this.validateBlurUser('tel')
        this.validateBlurUser('gender')
        this.validateBlurUser('day')
        this.validateBlurUser('month')
      }
    },
    async validateAndUpdatePasswords() {
      if (this.newPassword.length >= 8 && this.passwordNow) {
        this.validateUpdatePassword = false
        this.validatePassword = false
        this.loading = true
        await this.updatePassword({
          passwordNow: this.passwordNow,
          newPassword: this.newPassword,
        }).then(async () => {
          if (!this.errorUpdatePassPerfil) {
            this.passwordNow = ''
            this.newPassword = ''
            this.stepDataUser = 0
            this.updatePasswordalert = true
            await setTimeout(() => {
              this.updatePasswordalert = false
            }, 2500)
          }
          this.loading = false
        })
      } else {
        this.validateUpdatePassword = !(this.newPassword.length >= 8)
        this.validatePassword = !this.passwordNow
      }
    },
    validateBlurUser(blur) {
      if (blur == 'name') {
        this.validateNombre = !this.validateName
      } else if (blur == 'lastName') {
        this.validateApellido = !this.validateLastName
      } else if (blur == 'tipoDoc') {
        this.validateTipoDoc = !this.tipoDoc
      } else if (blur == 'doc') {
        this.validateDoc = !this.validateDocument
      } else if (blur == 'tel') {
        this.validateTel = !this.validatePhoneUser
      } else if (blur == 'gender') {
        this.validateGenero = !this.genero
      } else if (blur == 'day') {
        this.validateDia = !this.dia
      } else if (blur == 'month') {
        this.validateMes = !this.mes
      }
    },
    updateInfo() {
      this.$store.state.user.dataUser.persona.nombres = this.nombreUser
      this.$store.state.user.dataUser.persona.apellidos = this.apellidosUser
      this.$store.state.user.dataUser.persona.tipodoc = this.tipoDoc
      this.$store.state.user.dataUser.persona.documento = this.doc
      this.$store.state.user.dataUser.persona.celular = this.tel
      this.$store.state.user.dataUser.persona.genero = this.genero
      this.$store.state.user.dataUser.persona.fechanac_mes = this.mes
      this.$store.state.user.dataUser.persona.fechanac_dia = this.dia
    },
  },
}
</script>
