<template>
  <div>
    <header-page-derek
      v-if="dataUser"
      :title="
        'Hola ' + dataUser.persona.nombres + ', que bueno tenerte de vuelta'
      "
      :skeleton="false"
    />
    <div class="container-menu-nav-profile" :class="dataUser ? '' : 'pt-3'">
      <menu-profile-desktop />
      <menu-profile-movil />
    </div>
  </div>
</template>
<script>
import MenuProfileDesktop from '@/components/Profile/MenuNavProfile/MenuProfileDesktop'
import MenuProfileMovil from '@/components/Profile/MenuNavProfile/MenuProfileMovil'
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'

import { mapState } from 'vuex'
export default {
  components: { HeaderPageDerek, MenuProfileDesktop, MenuProfileMovil },
  computed: {
    ...mapState('user', ['dataUser']),
  },
}
</script>
